.pagination__block
    .pagination__items
        display flex
        justify-content center
        .pagination__item
            height 36px
            margin 0 6px
            background white
            round 3px
            border 1px solid gray
            &:not(.disabled)
                &:hover
                    background color-main-light  
                    span
                    a
                        color white
                &:active
                    background color-main-dark
                    span
                    a
                        color white
            &.disabled
                display none
                background gray-light
            &.active
                display block
                background color-main
                span
                a
                    color white
            span
            a
                display flex
                align-items center
                justify-content center
                width 100%
                height 100%
                padding 0 16px
                font-weight 500
                color color-font
