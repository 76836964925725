.advantages
    &__block
        padding 30px
        background gray-light
        .advantages__item
            position relative
            display flex
            +mq(lg-max)
                margin-bottom 30px
            &::after
                content ''
                absolute(0, 0)
                width 2px
                height 100%
                background rgba(gray-dark, 70%)
                +mq(lg-max)
                    display none
            .icon
                display flex
                align-items start
                justify-content end
                min-width 45px
                min-height 45px
                margin-right 15px
                svg
                    fill color-main
            &:nth-child(1)
                .icon
                    svg
                        width 41px
                        height 34px
            &:nth-child(2)
                .icon
                    svg
                        width 34px
                        height 34px
            &:nth-child(3)
                .icon
                    svg
                        width 34px
                        height 24px
            &:nth-child(4)
                &::after
                    display none
                .icon
                    svg
                        width 34px
                        height 34px
            .content
                .title
                    display block
                    font-size 14px
                    font-weight 500
                    color color-font
                    margin-bottom 8px
                .text
                    font-size 13px
                    color color-font-light
