.calc
    &__block
        position relative
        margin-top -36px
        padding 72px 0 36px 0
        &::after
            content ''
            absolute(0)
            width 100%
            height 100%
            background gray-light
            z-index -2
        .container
            .content
                position relative
                display flex
                align-items center
                width 100%
                height 360px
                padding 40px
                background linear-gradient(100deg, color-secondary 0%, color-main 100%)
                &::before
                    content ''
                    absolute(auto, 0, 0)
                    width 50%
                    height 340px
                    background url(/assets/images/label.png) no-repeat right bottom, url(/assets/images/calc.png) no-repeat 0px bottom
                    +mq(sm-max)
                        display none
                .info
                    max-width xs-min
                    margin-left 100px
                    color white
                    +mq(sm-max)
                        min-width 100%
                    .title
                        display block
                        margin 0
                        margin-bottom 20px
                        text-align left
                        font-size 26px
                        font-weight 600
                    .text
                        margin-bottom 20px
                        text-align left
                        font-weight 500
                    .calc__button
                        display flex
                        align-items center
                        justify-content center
                        height 54px
                        width fit-content
                        padding 0 30px
                        background color-main
                        font-weight 500
                        round 3px
                        cursor pointer
                        &:hover
                            background color-main-light
                        &:active
                            background color-main-dark
+mq(lg-max)
    .calc
        &__block
            .container
                .content
                    &::before
                        background url(/assets/images/label.png) no-repeat 130px bottom, url(/assets/images/calc.png) no-repeat 0px bottom
                    .info
                        margin 0
