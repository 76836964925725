footer
    background white
    border-top 1px solid gray
    padding-top 80px
    nav.main
        .contacts
            +mq(md-max)
                display flex
                justify-content space-between
                margin-bottom 30px
            +mq(sm-max)
                flex-direction column
                justify-content center
                text-align center
            .info
                 margin-bottom 30px
                .contact__item
                    display flex
                    margin-bottom 16px
                    font-size 16px
                    font-weight 500
                    +mq(sm-max)
                        justify-content center
                    svg
                        min-width 20px
                        width 20px
                        height 20px
                        margin-right 15px
                        fill color-main
                    span
                        color gray-darken
                    &.phone
                        display block
                        span
                            font-size 25px
                            font-weight 600
                            color color-main
            .buttons
                .callback__button
                    display flex
                    align-items center
                    justify-content center
                    height 54px
                    width 224px
                    font-weight 500
                    color color-main
                    background white
                    border 2px solid color-main
                    round 3px
                    cursor pointer
                    +mq(sm-max)
                        margin 0 auto
                    &:hover
                        color white
                        background color-main-light
                        border 2px solid color-main-light
                    &:active
                        color white
                        background color-main-dark
                        border 2px solid color-main-dark
                .social_icons
                    display flex
                    justify-content left
                    width 100%
                    margin-top 30px
                    +mq(md-max)
                        margin-top 12px
                    +mq(sm-max)
                        margin-top 30px
                        justify-content center
        .menu
            & > .catalog_menu
                    display flex
                    flex-direction column
                    align-content end
                    flex-wrap wrap
                    max-height 300px
                    +mq(md-max)
                        align-content start
                    +mq(sm-max)
                        flex-wrap unset
                        max-height none
                .title
                    margin-bottom 26px
                    -webkit-user-select none
                    -moz-user-select none
                    -ms-user-select none
                    user-select none
                    span, a
                        color color-main
                        font-size 16px
                        font-weight 600
                    .arrow
                        display none
                    +mq(sm-max)
                        display flex
                        justify-content space-between
                        align-items center
                        width 100%
                        padding 18px 0
                        margin 0
                        cursor pointer
                        border-bottom 1px solid gray
                        span, a
                            color color-font-light
                        .arrow
                            display flex
                            align-items center
                            justify-content center
                            height 15px
                            width 15px
                            transform rotate(180deg)
                            fill color-font-light
                        &:hover
                            span, a
                                color color-main-light
                            .arrow
                                fill color-main-light
                        &:active
                            span, a
                                color color-main-dark
                            .arrow
                                fill color-main-dark
                            
                        
                .menu__items
                    display contents
                    max-width 170px
                    +mq(sm-max)
                        display none
                        max-width none
                        &.active
                            display block
                    .menu__item
                        font-size 13px
                        margin-bottom 20px
                        a
                            font-size 13px
                            color color-font
                            padding 10px 0
                            &:hover
                                color color-main-light
                            &:active
                                color color-main-dark
                        &.current
                            color color-main-dark
                        +mq(sm-max)
                            a,span
                                display block
                                padding 6px 0
                                margin 12px 0
                            
    .bottom
        border-top 1px solid gray
        padding 40px 0
        margin-top 30px
        .info
            display flex
            align-items center
            justify-content space-between
            +mq(sm-max)
                flex-direction column
            a, span
                font-size 13px
                color color-font-light
            .info__item
                +mq(sm-max)
                    margin-bottom 30px
                &.policy
                    &:hover
                        color color-main-light
                    &:active
                        color color-main-dark
                        
                &.pays
                    i
                        display inline-block
                        vertical-align middle
                        margin 3px 10px
                        background url(/assets/images/svg/payment.svg) 0 0 no-repeat
                        opacity 25%
                        &.cacsh
                            width 20px
                            height 20px
                            background-position 0 -248px
                        &.mastercard
                            width 24px
                            height 16px
                            background-position -279px -250px
                        &.visa
                            width 31px
                            height 10px
                            background-position -40px -254px
                        &.yandex_money
                            width 15px
                            height 19px
                            background-position -92px -248px
                        &.webmoney
                            width 17px
                            height 18px
                            background-position -127px -249px
                        &.qiwi
                            width 19px
                            height 20px
                            background-position -165px -248px
                        &.sbrf
                            width 19px
                            height 20px
                            background-position -240px -248px
                        &.alfa
                            width 14px
                            height 19px
                            background-position -323px -248px
                        &.mir
                            width 36px
                            height 20px
                            background-position -357px -248px
                        &.jcb
                            width 22px
                            height 19px
                            background-position -412px -248px
                        &.paypal
                            width 15px
                            height 19px
                            background-position -205px -248px
                        &.maestro
                            width 24px
                            height 16px
                            background-position -279px -250px
                        &.yoomoney,&.yookassa
                            width 25px
                            height 18px
                            background-position -534px -249px
                        &.belkart
                            width 20px
                            height 20px
                            background-position -579px -248px
                        &.halva
                            width 18px
                            height 18px
                            background-position -619px -249px
                        &.tinkoff
                            width 63px
                            height 16px
                            background-position -794px -199px