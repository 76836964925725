.price_request__form
    .form__group
        margin-bottom 24px
        .form__label
            display block
            font-weight 500
            margin-bottom 6px
        .form__input
            display flex
            align-items center
            width 100%
            padding 12px
            background gray-light
            outline none
            border 1px solid gray
            round 3px
            &:focus
                padding 11px
                border 2px solid gray-dark
    .buttons
        display flex
        align-items center
        justify-content space-between
        margin-top 24px
        .form__button
            display flex
            align-items center
            justify-content center
            width calc(50% - 6px)
            padding 12px
            font-weight 500
            color color-main
            background white
            border 2px solid color-main
            round 3px
            cursor pointer
            &.submit
                color white
                background color-main
            &:hover
                color white
                background color-main-light
                border 2px solid color-main-light
            &:active
                color white
                background color-main-dark
                border 2px solid color-main-dark
    .privacy
        .policy
            margin 0
            margin-top 12px
            font-size 12px
            color color-font
            a
                font-size 12px
                color color-font
                &:hover
                    text-decoration underline