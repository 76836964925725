.order__block
    .checkout__item
        padding 36px
        background white
        border 1px solid gray
        margin-bottom 36px
        round 3px
        & > .title
            padding-bottom 36px
            font-size 20px
            font-weight 600
        .options
            position relative
            height 306px
            .options__item
                margin-bottom 30px
                label
                    display flex
                    align-items center
                    justify-content start
                    width calc(50% - 18px)
                    height 82px
                    padding 36px
                    font-size 16px
                    font-weight 600
                    border 1px solid gray
                    cursor pointer
                input
                    display none
                .description
                    absolute(0, 0, auto, auto)
                    display none
                    width calc(50% - 18px)
                    height 100%
                    padding-left 36px
                    border-left 1px solid gray
                &.active
                    label
                        color white
                        background color-main
                    .description
                        display block
                &:hover
                    label
                        color white
                        background color-main-light
                &:active
                    label
                        color white
                        background color-main-dark
        .credential__items
            .credential__item
                display flex
                flex-direction column
                margin-bottom 24px
                .form__label
                    font-weight 500
                    margin-bottom 6px
                .form__input
                    display flex
                    align-items center
                    width 100%
                    padding 12px
                    background gray-light
                    outline none
                    border 1px solid gray
                    round 3px
                    &:focus
                        padding 11px
                        border 2px solid gray-dark
                .form__textarea
                    height 100px
                    resize none
                    
    .checkout
        display flex
        flex-direction column
        justify-content center
        align-items center
        .privacy
            .policy
                margin 0
                margin-top 12px
                color color-font
                a
                    color color-font
                    &:hover
                        text-decoration underline
        .buttons
            margin-top 24px
            .checkout__button
                display flex
                align-items center
                justify-content center
                height 54px
                width fit-content
                padding 0 30px
                background color-main
                font-weight 500
                color white
                round 3px
                cursor pointer
                &:hover
                    background color-main-light
                &:active
                    background color-main-dark
            