.cart__block
    .cart
        padding 36px
        background white
        border 1px solid gray
        round 3px
    .cart__items
        display flex
        flex-direction column
        .cart__item
            position relative
            display flex
            +mq(sm-max)
                flex-wrap wrap
            &:not(:first-child)
                padding-top 36px
                border-top 1px solid gray
            &:not(:last-child)
                margin-bottom 36px
            .image
                display block
                img
                    max-width 126px
                    max-height 126px
                    +mq(sm-max)
                        max-width 70px
                        max-height 70px
            .info
                display flex
                flex-direction column
                justify-content space-between
                margin-left 30px
                +mq(sm-max)
                    max-width calc(100% - 100px)
                    flex-wrap wrap
                    flex-direction row
                .title
                    display block
                    font-size 16px
                    font-weight 500
                    color color-font
                    &:hover
                        color color-main-light
                    &:active
                        color color-main-dark
                .product_quantity
                    display flex
                    justify-content space-between
                    width 200px
                    margin-top 6px
                    background gray-light
                    border 1px solid gray
                    round 3px
                    .quantity__item
                        display flex
                        align-items center
                        justify-content center
                        height 40px
                        width 40px
                        font-size 24px
                        font-weight 300
                        cursor pointer
                        -webkit-user-select none
                        -moz-user-select none
                        -ms-user-select none
                        user-select none
                        &:hover
                            color color-main-light
                        &:active
                            color color-main-dark
                    .count
                        display block
                        width calc(100% - 80px)
                        border none
                        outline none
                        background gray-light
                        text-align center
                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button
                            /* display none <- Crashes Chrome on hover */
                            -webkit-appearance none
                            margin 0 
            .prices
                display flex
                flex-direction column
                align-items end
                min-width 180px
                margin-left auto
                padding-left 36px
                .price
                    font-size 18px
                    font-weight 600
                    display block
                    &::after
                        content '₽'
                        margin-left 6px
                    &.old_price
                        text-decoration line-through
                        color color-font-light
            .buttons
                absolute(auto, 0, 0, auto)
                .product_remove__button
                    display flex
                    align-items center
                    justify-content center
                    width fit-content
                    padding 12px 24px
                    font-weight 500
                    color white
                    background gray-light
                    border 1px solid gray
                    round 3px
                    cursor pointer
                    svg
                        width 17px
                        height 21px
                        margin-right 12px 
                        fill gray-dark
                    span
                        color gray-dark
                    &:hover
                        color white
                        background color-main-light
                        svg
                            fill white
                        span
                            color white
                    &:active
                        color white
                        background color-main-dark
                        svg
                            fill white
                        span
                            color white
    .cart__totals
        padding 36px
        background gray-light
        border 1px solid gray
        round 3px
        & > .title
            font-size 20px
            font-weight 500
        .details
            display flex
            flex-direction column
            margin-top 30px
            font-size 16px
            font-weight 500
            &__item
                display flex
                align-items center
                justify-content space-between
                margin-bottom 12px
                .title
                    display block
            .value
                margin-left auto
            .price
                &::after
                    content '₽'
                    margin-left 6px
            .weight
                &::after
                    content 'кг'
                    margin-left 6px
            .total_price
                margin-top 24px
                font-weight 600
        .buttons
            margin-top 30px
            .button
                display flex
                align-items center
                justify-content center
                width fit-content
                padding 12px
                font-weight 500
                color white
                background color-main
                round 3px
                cursor pointer
                &:hover
                    color white
                    background color-main-light
                &:active
                    color white
                    background color-main-dark
            
        
        