/*
===============================================
General Content Table
===============================================

1. Global
2. Grids
3. Headings
4. Links
5. Lists
6. Tables
7. Buttons
8. Margins And Paddings
9. Visibility
10. Form Elements
-------------------------------------------- */
/*


/*
=============================================== 
1. Global
===============================================
*/

*
    box-sizing border-box


*::before,
*::after 
    box-sizing inherit
    
html,
body 
    min-height 100%
    min-height 100vh
    
body 
    font 14px font-main
    background none
    color color-font
    padding 0
    margin 0
    text-rendering geometricPrecision

.section
    padding-bottom 36px
/*
=============================================== 
2. Grids
===============================================
*/  

/* 
=============================================== 
3. Headings
===============================================
*/

h1 
    font 1.5rem font-main
    
h2 
    font-size 1.3rem
    margin 12px 0
    font-weight 600
        
/* 
=============================================== 
4. Links
===============================================
*/

a 
    color color-main
    text-decoration none
    &:hover
        color color-main-light
    &:active
        color color-main-dark
    
/* 
=============================================== 
5. Lists
===============================================
*/

ul 
    list-style none
    padding 0
    margin 0
    
/* 
=============================================== 
6. Tables
===============================================
*/

table
    border-spacing 0
    border-collapse collapse
    +mq(md-min)
        table 
            display block
            overflow-x auto

/* 
=============================================== 
7. Buttons
===============================================
*/

.header-btn
    display block
            
/* 
=============================================== 
7. Icons
===============================================
*/

svg
    fill color-font


.social_icons
    .social__items
        position relative
        text-align center
        font-size 0
        line-height 0
        box-sizing border-box
        .social__item
            display inline-block
            vertical-align top
            margin 4px
            padding 0
            round(100px)
            a
                display block
                width 43px
                height 43px
                margin 0
                opacity 50%
                border 1px solid color-font
                round(100px)
                background url(/assets/images/svg/social.svg) 0 0 no-repeat
                &:hover
                    opacity 1
                    border none
            &.vk
                a
                    background-position 0 -565px
                    &:hover
                        background-position 0 -188px
            &.facebook
                a
                    background-position -95px -565px
                    &:hover
                        background-position -94px -188px
            &.twitter
                a
                    background-position 0 -612px
                    &:hover
                        background-position 0 -235px
            &.instagram
                a
                    background-position -142px -706px
                    &:hover
                        background-position -141px -329px
            &.telegram
                a
                    background-position -330px -706px
                    &:hover
                        background-position -329px -329px
            &.yt
                a
                    background-position -95px -706px
                    &:hover
                        background-position -94px -329px
            &.odn
                a
                    background-position -48px -565px
                    &:hover
                        background-position -47px -188px
            &.gplus
                a
                    background-position -48px -706px
                    &:hover
                        background-position -47px -329px
            &.mail
                a
                    background-position -377px -612px
                    &:hover
                        background-position -376px -235px

            &.viber
                a
                    background-position -330px -565px
                    &:hover
                        background-position -329px -188px
            &.whatsapp
                a
                    background-position -330px -612px
                    &:hover
                        background-position -329px -235px
            &.zen
                a
                    background-position -471px -706px
                    &:hover
                        background-position -470px -329px
            &.tiktok
                a
                    background-position -518px -706px
                    &:hover
                        background-position -517px -329px
            &.pinterest
                a
                    background-position -189px -565px
                    &:hover
                        background-position -188px -188px
            &.snapchat
                a
                    background-position -518px -659px
                    &:hover
                        background-position -517px -282px
            &.linkedin
                a
                    background-position -95px -659px
                    &:hover
                        background-position -94px -282px

/* 
=============================================== 
8. Margins And Paddings
===============================================
*/

/*
=============================================== 
9. Visibility
===============================================
*/ 
    
.visible
    visibility visible !important
    
.hidden
    visibility hidden !important   
    
.show
    display none !important 
    
.hide
    display none !important 
    

.desktop
    +mq(md-max)
        display none !important
.tablet
    +mq(sm-max)
        display none !important
.mobile
    display none !important
    +mq(md-max)
        display block !important
    
/* 
=============================================== 
10. Form Elements
===============================================
*/


*
    &:-ms-input-placeholder /* Internet Explorer 10-11 */
        color color-font-light
    &::-ms-input-placeholder /* Microsoft Edge */
        color color-font-light
    &::-webkit-input-placeholder
        color color-font-light
        opacity 1
    &:-moz-placeholder
        color color-font-light
        opacity 1
    &::placeholder /* Chrome, Firefox, Opera, Safari 10.1+ */
        color color-font-light
        opacity 1 /* Firefox */

/* 
=============================================== 
10. Popups
===============================================
*/
.popup
    display none
    &.active
        display block
        & + .backdrop
            display block
.backdrop
    display none
    position fixed
    top 0
    bottom 0
    left 0
    right 0
    background black
    opacity 0.3
    z-index 9999
    cursor pointer
    &.active
        display block

.popup
    position fixed
    top 16%
    left calc(50% - 300px)
    display none
    width 600px
    background white
    -webkit-box-shadow 0 0 20px 0px rgba(black, 0.25)
    -moz-box-shadow 0 0 20px 0px rgba(black, 0.25)
    box-shadow 0 0 20px 0px rgba(black, 0.25)
    border-radius 3px
    z-index 10000
    +mq(md-max)
        left calc(50% - 150px)
        width 300px
        top 16%
    .header
        padding 30px
        padding-bottom 24px
        box-sizing unset
        overflow hidden
        border-bottom 1px solid gray
        border-top-left-radius 3px
        border-top-right-radius 3px
        .title
            font-size 20px
            font-weight 600
        .close_popup
            position absolute;
            right 30px
            top 30px
            width 22px
            height 22px
            cursor pointer
            background-image url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' fill='%23C2C2C2' viewBox='0 0 24 24'%3E%3Cpath d='M18.984 6.422l-5.578 5.578 5.578 5.578-1.406 1.406-5.578-5.578-5.578 5.578-1.406-1.406 5.578-5.578-5.578-5.578 1.406-1.406 5.578 5.578 5.578-5.578z'%3E%3C/path%3E%3C/svg%3E%0A")
            background-repeat no-repeat
            background-size contain
            &:hover
                background-image url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' fill='%23FF813A' viewBox='0 0 24 24'%3E%3Cpath d='M18.984 6.422l-5.578 5.578 5.578 5.578-1.406 1.406-5.578-5.578-5.578 5.578-1.406-1.406 5.578-5.578-5.578-5.578 1.406-1.406 5.578 5.578 5.578-5.578z'%3E%3C/path%3E%3C/svg%3E%0A")
    .content
        padding 30px
