.delivery_and_payment__block
    .options
        .options__item
            display flex
            flex-direction column
            margin-top 24px
            round 3px
            -webkit-user-select none
            -moz-user-select none
            -ms-user-select none
            user-select none
            .image
                position relative
                height 100px
                width 100%
                img
                    absolute0()
                    width 100%
                    height 100%
                    z-index -1
                .title
                    display flex
                    align-items end
                    width 100%
                    height 100%
                    padding 30px
                    font-size 1rem
                    font-weight 600
                    color white
                    background linear-gradient(90deg, #EB6216 0%, rgba(235, 98, 22, 0.96) 30.73%, rgba(235, 98, 22, 0.54) 100%);
            .content
                flex auto
                padding 30px
                font-size 13px
                background gray-light
                round-bottom 3px
                p
                    margin 0
                    &:not(:last-child)
                        margin-bottom 24px
                .note
                    color color-main