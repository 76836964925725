.product__section
    .product_details
        +mq(md-max)
            margin-top 30px
        .stock
            font-weight 500
            color color-main
        .article
            font-weight 500
        .product_quantity
            margin-top 30px
            .title
                font-weight 600
            .select
                display flex
                justify-content space-between
                margin-top 6px
                background gray-light
                border 1px solid gray
                round 3px
                .quantity__item
                    display flex
                    align-items center
                    justify-content center
                    height 40px
                    width 40px
                    font-size 24px
                    font-weight 300
                    cursor pointer
                    -webkit-user-select none
                    -moz-user-select none
                    -ms-user-select none
                    user-select none
                    &:hover
                        color color-main-light
                    &:active
                        color color-main-dark
            
                .count
                    display block
                    width calc(100% - 80px)
                    border none
                    outline none
                    background gray-light
                    text-align center
                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button
                        /* display none <- Crashes Chrome on hover */
                        -webkit-appearance none
                        margin 0 /* <-- Apparently some margin are still there even though it's hidden */
        .product_info
            margin-top 30px
            padding 30px
            background gray-light
            border 1px solid gray
            round 3px
            .prices
                .price
                    margin-right 30px
                    font-size 18px
                    font-weight 600
                    &:not(.request_price)
                        &::after
                            content '₽'
                            margin-left 6px
                    &.old_price
                        text-decoration line-through
                        color color-font-light
            .delivery
                display flex
                align-items space-between
                flex-direction column
                margin-top 30px
                &__item
                    display flex
                    align-items center
                    margin-bottom 12px
                    .title
                        font-weight 500
                        color color-main
                    .info
                        font-weight 500
                        .delivery_date
                            +mq(sm-max)
                                display none
            .buy__buttons
                display flex
                align-items center
                justify-content space-between
                margin-top 30px
                .button
                    display flex
                    align-items center
                    justify-content center
                    width calc(50% - 12px)
                    padding 12px
                    font-weight 500
                    color color-main
                    background gray-light
                    border 2px solid color-main
                    round 3px
                    cursor pointer
                    &.order__button
                        color white
                        background color-main
                    &:hover
                        color white
                        background color-main-light
                        border 2px solid color-main-light
                    &:active
                        color white
                        background color-main-dark
                        border 2px solid color-main-dark
    .product_options
        .options__list
            display grid
            grid-template-columns 1fr 1fr 1fr
            grid-auto-flow row
            grid-gap 12px
            +mq(md-max)
                grid-template-columns 1fr
            .options__item
                display flex
                justify-content space-between
                .title
                    width 50%
                    font-weight 500
                    color color-font-light
                .value
                    display block
                    width 50%
                    text-align left
                    word-break break-word
                    font-weight 500
    .tabs
        margin-top 60px
        .tabs__list
            display flex
            border-bottom 1px solid gray
            .tabs__item
                display block
                padding 6px 0
                margin-right 5%
                font-size 18px
                font-weight 500
                color color-font-light
                border-bottom 2px solid rgba(white, 0)
                cursor pointer
                &.active
                    color color-main
                    border-color color-main
                &:hover
                    color color-main-light
                    border-color color-main-light
                &:active
                    color color-main-dark
                    border-color color-main-dark
        .tabs__content
            .tabs__item
                display none
                padding 30px 0
                &.active
                    display block
            #tab-description
                img
                    float right
        
