@import 'vars.styl'
@import 'mixins.styl'

#svg-icons
	*:not(#icon-large_logo)
		path,
		line
			fill inherit
			stroke inherit
	#icon-compare
		path,
		line
			fill none
			stroke inherit
