.gallery__block
    position relative
    .nav
        .arrow
            -webkit-user-select none
            -moz-user-select none
            -ms-user-select none
            user-select none
    &___slider
        +mq(md-max)
            margin 0 auto
        .gallery__item___slider
            width 260px
            cursor pointer
            img
                display block
                margin 0 auto
                max-width 100%
                max-height 500px
        &:hover
            .nav
                display block
        .nav
            display none
            .arrow
                width 42px
                height 42px
                background color-main
                round 3px
                &:hover
                    background color-main-light
                &:active
                    background color-main-dark
                &::after
                    color white
                    font-size 20px
        .pagination
            display none
    &___detail
        margin-left 72px
        +mq(md-max)
            margin 0 auto
        .gallery__item___detail
            width 500px
            cursor pointer
            img
                display block
                margin 0 auto
                max-width 100%
                max-height 500px
        &:hover
            .nav
                display block
        .nav
            display none
            .arrow
                width 42px
                height 42px
                background color-main
                round 3px
                &:hover
                    background color-main-light
                &:active
                    background color-main-dark
                &::after
                    color white
                    font-size 20px
        .pagination
            .swiper-pagination-bullet-active
                background color-main
    &___thumbs
        absolute(0, auto, 0, 0)
        max-height 500px
        +mq(md-max)
            display none
        .gallery__item___thumbs
            max-width 60px
            max-height 60px
            width 60px
            height 60px
            cursor pointer
            border 2px solid rgba(white, 0%)
            img
                width 56px
                height 56px
            &.swiper-slide-thumb-active
                border 2px solid color-main
                round 3px
            &:hover
                border 2px solid color-main-light
            &:active
                border 2px solid color-main-dark
        .nav
            display none