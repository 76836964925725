.call_request
    &__block
        position relative
        padding 72px 0 36px 0
        &::after
            content ''
            absolute(0)
            width 100%
            height 100%
            background gray-light
            z-index -2
        &___advantages
            .advantages__list
                padding 24px 0
                .advantages__item
                    .content
                        display flex
                        +mqb(md-min, md-max)
                            flex-direction column
                            align-items center
                            text-align center
                        +mq(md-max, max)
                            margin-bottom 20px
                            
                        .icon
                            display flex
                            align-items start
                            justify-content end
                            min-width 45px
                            min-height 45px
                            margin-right 15px
                            +mqb(md-min, md-max)
                                margin-bottom 12px
                            svg
                                fill color-main
                    &:nth-child(1)
                        .icon
                            svg
                                width 43px
                                height 60px
                    &:nth-child(2)
                        .icon
                            svg
                                width 46px
                                height 60px
                    &:nth-child(3)
                        .icon
                            svg
                                width 59px
                                height 48px
    &__form
        position relative
        width 100%
        min-height 340px
        padding 80px 10%
        background linear-gradient(100deg, color-main 0%, color-secondary 100%)
        .title
            display block
            margin 0
            margin-bottom 24px
            text-align left
            font-size 26px
            font-weight 600
            color white
        .form
            &__items
                display flex
                align-items center
                justify-content space-between
            &__item
                display flex
                align-items center
                height 44px
                margin-bottom 24px
            &__input,&__button
                color white
                background none
                border none
                outline none
            &__input
                height 100%
                width 100%
                font-weight 500
                border-bottom 1px solid white
                &::placeholder /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color white
                    opacity 80% /* Firefox */
                &:-ms-input-placeholder /* Internet Explorer 10-11 */
                    color white
                &::-ms-input-placeholder /* Microsoft Edge */
                    color white
            &__button
                justify-content center
                height 44px
                width 100%
                text-align center
                background color-main
                font-weight 500
                round 3px
                cursor pointer
                &:hover
                    background color-main-light
                &:active
                    background color-main-dark
        .privacy
            .policy
                margin 0
                margin-top 12px
                color white
                a
                    color white
                    &:hover
                        text-decoration underline
