.personal__form
    position relative
    width 40%
    .form__item
        display flex
        align-items center
        height 44px
        margin-bottom 24px
    .form__input,&__button
        display flex
        align-items center
        padding 12px
        background gray-light
        outline none
        border 1px solid gray
        round 3px
        &:focus
            padding 11px
            border 2px solid gray-dark
    .form__input
        height 100%
        width 100%
        font-weight 500
        &::placeholder /* Chrome, Firefox, Opera, Safari 10.1+ */
            color color-font-light
            opacity 80% /* Firefox */
        &:-ms-input-placeholder /* Internet Explorer 10-11 */
            color color-font-light
        &::-ms-input-placeholder /* Microsoft Edge */
            color color-font-light
    .form__button
        display flex
        align-items center
        justify-content center
        padding 12px
        width 100%
        font-weight 500
        color color-main
        background white
        border 2px solid color-main
        round 3px
        cursor pointer
        &.submit
            color white
            background color-main
        &:hover
            color white
            background color-main-light
            border 2px solid color-main-light
        &:active
            color white
            background color-main-dark
            border 2px solid color-main-dark
    .privacy
        .policy
            margin 0
            margin-top 12px
            color white
            a
                color white
                &:hover
                    text-decoration underline
