.contacts__block
    padding-top 24px
    .contacts
        .contacts__item
            display flex
            margin-bottom 24px
            .icon
                display flex
                align-items start
                justify-content end
                min-width 45px
                min-height 45px
                margin-right 15px
                svg
                    width 43px
                    height 43px
                    fill color-main
            &:nth-child(2)
                .icon
                    svg
                        width 43px
                        height 33px
            .content
                .title
                    display block
                    margin-bottom 8px
                    font-weight 500
                    color color-font-light
                .text
                    font-size 16px
                    font-weight 500
                    color color-font
    .social_icons
        display flex
        justify-content left
    .map
        margin-top 24px
