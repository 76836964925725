.article__section
    .banner
        width 100%
        max-height 320px
        margin-bottom 30px
        img
            width 100%
            max-height 100%
            height 100%
    .article
        h1.title
            font-size 30px
            font-weight 600
            margin 0
            margin-bottom 30px
        .info
            display flex
            justify-content space-between
            max-width 20%
            margin-bottom 30px
            font-size 13px
            font-weight 500
            color color-main
            .published
                .month
                    margin 0 6px
            .views
                display flex
                align-items center
                margin-right 30px
                .count
                    color color-main
                .icon
                    width 17px
                    height 10px
                    fill color-main
                    margin-left 6px
        .text
            h2
                font-size 24px
                font-weight 600
            li
                margin-bottom 12px
            .strong
                font-weight 600
    .more
        height fit-content
        padding 30px
        background gray-light
        round 3px
        h2
            margin 0
            margin-bottom 30px
            font-size 24px
            font-weight 600
        .article__items
            .article__item___small
                display flex
                &:not(:last-child)
                    margin-bottom 30px
                .image.link
                    margin-right 12px
                    img
                        width 75px
                        height 75px
                        round 3px
                .content
                    display flex
                    flex-direction column
                    justify-content space-between
                    width 100%
                    .title.link
                        max-height 55px
                        font-size 16px
                        font-weight 600
                        color color-font
                        &:hover
                            color color-main-light
                        &:active
                            color color-main-dark
                    .info
                        display flex
                        justify-content space-between
                        margin-bottom 6px
                        font-size 13px
                        font-weight 500
                        color color-font-light
                        .published
                            .month
                                margin 0 6px
                        .views
                            display flex
                            align-items center
                            margin-left 12px
                            .count
                                color color-font
                            .icon
                                width 17px
                                height 10px
                                fill color-font
                                margin-left 6px
                    