.banner__block
    .banner
        height 450px
        .swiper
            height 450px
            .banner__item
                .image
                    position absolute
                    min-width 100%
                    height 450px
                    +mq(xl-max)
                        right -30%
                    +mq(lg-max)
                        right -40%
                    +mq(md-max)
                        right -60%
                    +mq(sm-max)
                        right auto
                .content
                    absolute(100px, auto, 0)
                    max-height 250px
                    .info
                        position absolute
                        width xs-min
                        .title
                            margin 0
                            margin-bottom 20px
                            text-align left
                            font-size 36px
                            font-weight 600px
                            color white
                        .text
                            margin 0
                            margin-bottom 20px
                            text-align left
                            color white
                        .button
                            display flex
                            align-items center
                            justify-content center
                            height 54px
                            width fit-content
                            padding 0 30px
                            background color-main
                            font-weight 500
                            color white
                            round 3px
                            cursor pointer
                            &:hover
                                background color-main-light
                            &:active
                                background color-main-dark