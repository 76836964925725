.reviews__block
    .reviews__item
        padding 24px
        border 1px solid gray
        &:not(:last-child)
            border-bottom none
        .content
            .info
                display flex
                justify-content space-between
                .author
                    font-weight 500
                    font-size 16px
                .date
                    color color-font-light
                .rating
                    margin-left auto
            .text
                margin-top 12px
                
        