.partners
    &__block
        position relative
        display flex
        align-items center
        margin-top -36px
        padding 72px 0 36px 0
        &::after
            content ''
            absolute(0)
            width 100%
            height 100%
            background gray-light
            z-index -2
    &__item
        display flex
        align-items center
        max-width 100%
        max-height 72px
        margin-bottom 30px
        .image
            max-width 100%
            width fit-content
            height fit-content
            filter grayscale(1)