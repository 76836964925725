.breadcrumbs
    &__list
        display flex
        flex-wrap wrap
        padding 24px 0
        .separator
            margin-right 24px
    &__item
        display inline-block
        margin-right 24px
        margin-bottom 12px
        font-weight 500
        a
            font-weight 500
        
        