.title__block
    position relative
    display flex
    align-content center
    justify-content space-between
    margin 24px 0
    border-bottom 1px solid gray
    .title
        text-align left
        font-size 1.3rem
        font-weight 600
        color color-font
        margin 8px 0
        -webkit-user-select none
        -moz-user-select none
        -ms-user-select none
        user-select none
    .nav
        .arrow
            width 32px
            height 32px
            left 300px
            padding 10px
            color gray
            border 1px solid gray
            round(50px)
            &.swiper-button-next
                left 350px
            &::after
                font-size 16px
            &:hover
                color color-main-light
                border 1px solid color-main-light
            &:active
                color color-main-dark 
                border 1px solid color-main-dark
            &.swiper-button-disabled
                opacity 0.7
    .links
        display flex
        align-items center
        .links__item
            display flex
            align-items center
            height 100%
            margin-left 30px
            cursor pointer
            &.current
                border-bottom 2px solid color-main
                &:hover
                    border-bottom 2px solid color-main-light
                &:active
                    border-bottom 2px solid color-main-dark
            span
                font-weight 500
                color color-main
            .arrow
                width 11px
                height 5px
                margin-left 6px
                transform rotate(90deg)
                fill color-main
            &:hover
                border-bottom 2px solid color-main-light
                span
                    color color-main-light
                .arrow
                    fill color-main-light
            &:active
                border-bottom 1px solid color-main-dark
                &.current
                    border-bottom 1px solid color-main
                span
                    color color-main-dark
                .arrow
                    fill color-main-dark