.sort__block
    display flex
    align-items center
    justify-content space-between
    margin-bottom 24px
    .sort__type
        position relative
        .title
            -webkit-user-select none
            -moz-user-select none
            -ms-user-select none
            user-select none
        .sort__item
            cursor pointer
            -webkit-user-select none
            -moz-user-select none
            -ms-user-select none
            user-select none
        &.sort_params
            .title
                display flex
                align-items center
                justify-content space-between
                min-width 200px
                padding 12px
                border 1px solid gray
                round 3px
                cursor pointer
                .arrow
                    width 12px
                    height 6px
                    transform rotate(180deg)
                    -webkit-transition transform .2s ease-in-out
                    -moz-transition transform .2s ease-in-out
                    -o-transition transform .2s ease-in-out
                    transition transform .2s ease-in-out
            .sort__items
                absolute(100%, 0, auto, 0)
                display none
                background white
                border 1px solid gray
                z-index 4
                round-bottom(3px)
                .sort__item
                    padding 6px 12px
                    margin  6px 0
                    &:hover
                        color color-main-light
                    &:active
                        color color-main-dark
                    &.selected
                        display none
                    
            &.active
                .title
                    .arrow
                        transform rotate(360deg)
                .sort__items
                    display block
        &.sort_view
            .sort__item
                margin-left 12px
                svg
                    height 18px
                    width 18px
                    fill color-font
                &.selected
                    svg
                        fill color-main
                &:hover
                    svg
                        fill color-main-light
                &:active
                    svg
                        fill color-main-dark
                        