.content__block
    padding-bottom 24px
    table
        display flex
        width 100%
        border none
        margin-bottom 24px
        th
        td
            padding 8px 12px
            border 1px solid gray
        tr
            border-color gray
        &:not(.flip)
            thead
                display block
                float left
                th
                td
                    display block
                    width 200px
                    font-weight 500
                    background color-main
                    color white
                    &:not(:last-child)
                        border-bottom 0 none
            tbody
                position relative
                display block
                width auto
                overflow-x auto
                white-space nowrap
                tr
                    display inline-block
                    vertical-align top
                    border-right 1px solid gray
                td
                    display block
                    border-left none
                    border-right none
                    white-space normal
                    &:not(:last-child)
                        border-bottom 0 none
                    +mq(md-max)
                        max-width 150px
