.filter__block
    &___desktop
        padding 30px 24px
        margin-bottom 24px
        border 1px solid gray
        round 3px
        .props__items
            .props__item
                margin-bottom 24px
                .title
                    display flex
                    align-items center
                    justify-content space-between
                    font-weight 500
                    cursor pointer
                    -webkit-user-select none
                    -moz-user-select none
                    -ms-user-select none
                    user-select none
                    .arrow
                        width 12px
                        height 6px
                        transform rotate(180deg)
                        -webkit-transition transform .2s ease-in-out
                        -moz-transition transform .2s ease-in-out
                        -o-transition transform .2s ease-in-out
                        transition transform .2s ease-in-out
                    &.closed
                        .arrow
                            transform rotate(360deg)   
                        & + .props_values__items
                            display none
                .props_values__items
                    .props_values__item
                        margin-top 12px
                        &___checkbox
                            display flex
                            align-items center
                            .checkbox
                                margin-right 12px
                                accent-color color-main
                            .value
                                font-size 13px
                                cursor pointer
                            &.checked
                                .value
                                    color color-main
        .buttons
            display flex
            align-items center
            justify-content space-between
            .form__button
                display flex
                align-items center
                justify-content center
                height 34px
                width calc(50% - 6px)
                padding 0 12px
                font-size 13px
                font-weight 500
                color color-main
                background white
                border 2px solid color-main
                round 3px
                cursor pointer
                &.reset
                    color white
                    background color-main
                &:hover
                    color white
                    background color-main-light
                    border 2px solid color-main-light
                &:active
                    color white
                    background color-main-dark
                    border 2px solid color-main-dark
                                    
                                
    &___mobile
        display none