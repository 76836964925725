.examples
    &__block
        position relative
        margin-top -36px
        padding 72px 0 36px 0
        &.main::after
            content ''
            absolute(0)
            width 100%
            height 100%
            background gray-light
            z-index -2
    &__item
        margin-bottom 24px
        .link
            position relative
            display flex
            align-items end
            height 375px
            background linear-gradient(0, rgba(black, 0.7) 0, rgba(black, 0.2) 100%)
            &:hover
                background linear-gradient(0, rgba(color-secondary, 0.5) 0, rgba(black, 0.2) 100%), linear-gradient(0, rgba(black, 0.7) 0, rgba(black, 0) 100%)
            .image
                absolute()
                width 100%
                height 100%
                z-index -1
            .content
                display flex
                align-items end
                justify-content start
                padding 30px
                .info
                    .title
                        text-align left
                        font-size 18px
                        font-weight 500
                        color white
                    .text
                        max-width 80%
                        text-align left
                        color white
                