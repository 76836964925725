.tags__block
    .tags__list
        display flex
        flex-wrap wrap
        .tags__item
            margin 0 12px 12px 0
            padding 6px 12px
            font-size 13px
            color color-font
            background gray-light
            round 50px
            &:hover
                color white
                background color-main-light
            &:active
                color white
                background color-main-dark