@import 'vars.styl'
@import 'mixins.styl'

/*
=============================================== 
Global                    
===============================================
*/

@import 'global/normalize.styl'

@import 'global/boostrap_grid.styl'

@import 'global/fonts.styl'

@import 'global/svg.styl'

@import 'global/common.styl'


/*
===============================================
Elements
===============================================
*/

/* GENERAL
=============================================== */

@import 'elements/header.styl'

@import 'elements/footer.styl'



/* FORMS
=============================================== */

@import 'elements/personal__form.styl'
@import 'elements/callback__form.styl'
@import 'elements/price_request__form.styl'
//@import 'elements/call_request__form.styl'

//@import 'elements/product_search__form.styl'


/* BLOCKS
=============================================== */

@import 'elements/breadcrumbs__block.styl'

@import 'elements/title__block.styl'

@import 'elements/banner__block.styl'

@import 'elements/advantages__block.styl'

//@import 'elements/category__block.styl'

@import 'elements/calc__block.styl'

@import 'elements/examples__block.styl'

@import 'elements/compilations__block.styl'

@import 'elements/looked__block.styl'

@import 'elements/call_request__block.styl'

@import 'elements/partners__block.styl'

@import 'elements/articles__block.styl'

@import 'elements/filter__block.styl'

@import 'elements/sort__block.styl'

@import 'elements/gallery__block.styl'

@import 'elements/contacts__block.styl'

@import 'elements/delivery_and_payment__block.styl'

@import 'elements/tags__block.styl'

@import 'elements/cart__block.styl'

@import 'elements/checkout__block.styl'

@import 'elements/reviews__block.styl'

@import 'elements/content__block.styl'

@import 'elements/pagination__block.styl'

/* ITEMS
=============================================== */

@import 'elements/product__item.styl'


/* SECTIONS
=============================================== */

@import 'elements/product__section.styl'
@import 'elements/example__section.styl'
@import 'elements/article__section.styl'