round(val-round)
    -webkit-border-radius val-round 
    -moz-border-radius val-round 
    border-radius val-round 

round-top(val-round)
    -webkit-border-top-left-radius val-round
    -webkit-border-top-right-radius val-round 
    -moz-border-top-left-radius val-round 
    -moz-border-top-right-radius val-round
    border-top-left-radius val-round
    border-top-right-radius val-round

round-bottom(val-round)
    -webkit-border-bottom-left-radius val-round
    -webkit-border-bottom-right-radius val-round 
    -moz-border-bottom-left-radius val-round 
    -moz-border-bottom-right-radius val-round
    border-bottom-left-radius val-round
    border-bottom-right-radius val-round
    
round-left(val-round)
    -webkit-border-top-left-radius val-round
    -webkit-border-bottom-left-radius val-round 
    -moz-border-top-left-radius val-round 
    -moz-border-bottom-left-radius val-round
    border-top-left-radius val-round
    border-bottom-left-radius val-round

round-right(val-round)
    -webkit-border-top-right-radius val-round
    -webkit-border-bottom-right-radius val-round 
    -moz-border-top-right-radius val-round 
    -moz-border-bottom-right-radius val-round
    border-top-right-radius val-round
    border-bottom-right-radius val-round